const About = () => {
  return (
    <>
      <div>
        <h2>About Alex</h2>

        <div className="col-sm-8">
          <p>
            Alex Mallory is the director of The Metal Shop Performance Lab,
            founded with the mission to create theatrical events that foster
            radical community-building and authentic exchange between artists
            and audiences. Alex's MFA thesis investigates how rehearsing
            authenticity and authorship in the theatrical process helps to
            create productive, brave spaces for conversations on personal and
            collective histories of violence for artists and audience members.
          </p>

          <p>
            Directing highlights include MARY STUART, an original adaptation of
            SPRING AWAKENING, GOLIATH, DIJLA WAL FURAT, THE REFUGE PROJECT, and
            FAITH. She has directed and developed work with Lincoln Center,
            BEDLAM, Culture Project's Women Center Stage, The Working Theatre,
            Classical Theater of Harlem, Dixon Place, Writopia Worldwide Plays
            Festival, the Samuel French Short Play Festival, The Eagle Project,
            and Poetic Theater Productions, where she served as Co-Artistic
            Director from 2011-2015.
          </p>

          <p>
            As a facilitator for DE-CRUIT, Alex helps military veterans to
            unlearn unwanted behaviors and heal from trauma through the body
            using classical acting training and Shakespearean texts. She has
            worked closely with the veteran community for nearly a decade,
            producing THE HOME SHOW at Lincoln Center in New York City with the
            Veteran Artist Program and directing veteran-driven work for Poetic
            Theater Productions, Piven Theatre, The Telling Project, and
            LaGuardia Community College, from whom she received an award in 2016
            for continued service to the veteran community.
          </p>

          <p>
            Alex holds an MFA in Directing from Northwestern University and a BA
            from Stanford University where she received the Louis Sudler Prize
            in Creative Arts and the Sherifa Omade Edoga Prize for work
            involving social issues. She has assisted at Steppenwolf,
            Lookingglass, Goodman Theatre, and as a FAIR Fellow at the Oregon
            Shakespeare Festival. Finalist, National Directors Fellowship 2020.
            Associate Member, SDC.
          </p>
        </div>
        <div className="col-sm-4 headshot">
          <img
            src="/images/newheadshot.jpeg"
            className="img-responsive headshot"
          />
          <p>
            {/* <p style="text-align:right;font-size:13px"> */}
            Photo by Suzanne Plunkett.
          </p>
        </div>
      </div>
    </>
  );
};
export default About;
