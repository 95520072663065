import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/footer';
import Nav from './components/nav';
import About from './pages/about';
import Contact from './pages/contact';
import Fund from './pages/fund';
import Gallery from './pages/gallery';
import Home from './pages/home';
import Media from './pages/media';
import NotFound from './pages/notFound';
import Resume from './pages/resume';
import Reviews from './pages/reviews';

function App() {
  return (
    <Router>
      <Nav />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/fund" element={<Fund />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/media" element={<Media />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
