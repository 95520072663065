const Resume = () => {
  return (
    <div>
      <h2>Résumé</h2>
      <embed
        src="/resumes/AlexMallory_Director_2023.pdf"
        style={{
          width: '100%',
          height: 800,
        }}
        className="resume_embed"
      />
    </div>
  );
};
export default Resume;
