const Contact = () => {
  return (
    <>
      <h2>Contact</h2>

      <div>
        <p>
          <strong>Interested in working with me?</strong> <br />
          Email alex k mallory [at] gmail [dot] com.
        </p>
        <p>
          <strong>Interested in hearing more about The Metal Shop?</strong>
          <br />
          Visit <a href="https://www.themetalshop.org">
            www.themetalshop.org
          </a>{' '}
          or email alex [at] themetalshop [dot] org.
        </p>
        <p>
          <strong>
            Interested in my work with Shakespeare and military veterans?
          </strong>
          <br />
          Visit <a href="https://www.decruit.org">www.decruit.org</a> or email
          alex [at] decruit [dot] org.
        </p>
      </div>
    </>
  );
};
export default Contact;
