import { useEffect } from 'react';

const Fund = () => {
  const url =
    'https://fundraising.fracturedatlas.org/the-metal-shop-performance-lab';

  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return (
    <>
      <h1>Redirecting...</h1>
      <p>
        If you are not redirected automatically, please navigate to{' '}
        <a href={url}>{url}</a> to access the donation page.
      </p>
    </>
  );
};

export default Fund;
