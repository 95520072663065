import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.css';

const Nav = () => {
  const location = useLocation();
  const [page, setPage] = useState('');
  const [subPage, setSubPage] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setPage('home');
        break;
      case '/gallery':
        setPage('gallery');
        break;
      case '/about':
      case '/resume':
        setPage('about');
        break;
      case '/reviews':
      case '/media':
        setPage('press');
        break;
      case '/contact':
        setPage('contact');
        break;
      default:
        setPage('');
    }

    switch (location.pathname) {
      case '/about':
        setSubPage('about');
        break;
      case '/resume':
        setSubPage('resume');
        break;
      case '/reviews':
        setSubPage('reviews');
        break;
      case '/media':
        setSubPage('media');
        break;
      default:
        setSubPage('');
    }
  }, [location.pathname]);

  const active = (linkTo) => (page === linkTo ? 'active' : '');
  const subActive = (linkTo) => (subPage === linkTo ? 'active' : '');

  return (
    <>
      <div
        style={{
          padding: '20px 20px 1px',
          backgroundColor: 'rgb(34, 34, 34)',
        }}
      >
        <h1
          style={{
            color: '#f5f5f5',
            fontSize: 40,
          }}
        >
          ALEX MALLORY: DIRECTOR
        </h1>
      </div>

      <nav className="navbar navbar-inverse navbar-static-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div id="navbar" className="navbar-collapse collapse">
            <ul className="nav navbar-nav">
              <li className={active('home')}>
                <Link to="/">Home</Link>
              </li>
              <li className={active('gallery')}>
                <Link to="/gallery">Portfolio</Link>
              </li>
              <li className={`dropdown ${active('about')}`}>
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  <li className={subActive('about')}>
                    <Link to="/about">Bio</Link>
                  </li>
                  <li className={subActive('resume')}>
                    <Link to="/resume">Résumé</Link>
                  </li>
                </ul>
              </li>
              <li className={`dropdown ${active('press')}`}>
                <a
                  // needs to be an anchor tag for bootstrap nav
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Press <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  <li className={subActive('reviews')}>
                    <Link to="/reviews">Reviews</Link>
                  </li>
                  <li className={subActive('media')}>
                    <Link to="/media">Media</Link>
                  </li>
                </ul>
              </li>
              <li className={active('contact')}>
                <Link to="/contact">Contact</Link>
              </li>
              <li className={active('fund')}>
                <Link to="/fund" target="_blank">
                  Fund
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Nav;
